export function formatCreditCard(number:string){
    const chunks = []
    for(let i = 0; i < number.length && i < 16; i += 4){
        chunks.push(number.slice(i, i+4))
    }
    return chunks.join(" ")+number.slice(16)
}

export function isIPhone(){
    const platform = navigator?.userAgent || navigator?.platform || "unknown"

    return /iPhone/.test(platform)
}

export function wait(ms:number){
    return new Promise(resolve => setTimeout(resolve, ms))
}