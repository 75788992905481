import { Box, ThemeProvider, CssBaseline } from "@mui/material"
import { HashRouter, Route, Routes } from "react-router-dom";
import { Toaster } from "react-hot-toast";
import dark from "./themes/dark";
import PaymentForm from "./pages/PaymentForm";
import Home from "./pages/Home";
import Loading from "./pages/Loading";

function App() {
    return <ThemeProvider theme={dark}>
        <CssBaseline />
        <Box sx={{
            minHeight: "var(--window-height)"
        }} bgcolor="background">
            <Toaster position="bottom-center" toastOptions={{
                duration: 5000,
                style: {
                    background: "#333",
                    color: "#fff",
                }
            }}/>
            <HashRouter>
                <Routes>
                    <Route path="/" element={<Home />} />
                    <Route path="/loading" element={<Loading />} />
                    <Route path="/:order_id/:token" element={<PaymentForm />} />
                </Routes>
            </HashRouter>
        </Box>
    </ThemeProvider>
}

export default App