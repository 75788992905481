export interface SumupCard {
    name: string,
    number: string,
    expiry_month: string,
    expiry_year: string,
    cvv: string
}

export interface SumupApplePaySessionPayload {
    target: string,
    context: string,
}

export default new class SumupClient {
    apiUrl = "https://api.sumup.com/v0.1"
    headers = new Headers()

    async fetch(method: "GET"|"POST"|"PUT", endpoint:string, body?:unknown, headers?:Headers){
        console.log(`[SumUp] ${method} ${endpoint}`)
        const h = new Headers(this.headers)
        for(const key of headers?.keys() ?? []){
            h.set(key, headers!.get(key)!)
        }
        
        let b = null
        if(method !== "GET"){
            b = JSON.stringify(body)
            h.set("Content-Type", "application/json")
        }

        const res = await fetch(this.apiUrl+endpoint, {
            headers: h,
            body: b,
            redirect: "manual",
            method: method
        })
        if(!res.ok){
            const text = await res.text()
            try{
                const json = JSON.parse(text)
                throw new Error(json.error_message)
            }catch(err){
                throw new Error(text)
            }
        }
        return res
    }

    async processCardCheckout(id: string, card: SumupCard){
        const res = await this.fetch("PUT", `/checkouts/${id}`, {
            payment_type: "card",
            card: card
        })
        return await res.json()
    }

    async processApplePayCheckout(id: string, payment: ApplePayJS.ApplePayPayment){
        const res = await this.fetch("PUT", `/checkouts/${id}`, {
            payment_type: "apple_pay",
            apple_pay: payment
        })
        return await res.json()
    }

    async initiateApplePaySession(id: string, payload: SumupApplePaySessionPayload) {
        const res = await this.fetch("PUT", `/checkouts/${id}/apple-pay-session`, payload)
        return await res.json()
    }
}