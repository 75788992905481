export enum OrderStatus {
    pending = "pending",
    paid = "paid",
    cancelled = "cancelled",
    failed = "failed",
    refunded = "refunded",
}
export interface Order {
    id: string;
    tickets: any[];
    user: string;
    status: OrderStatus;
    price: {
        amount: number;
        currency: string;
    };
    vat: {
        amount: number;
        currency: string;
    };
    vat_rate: number;
    created_at: number;
    pdf_token: string;
}

export interface CardInfo {
    provider: "sumup.0"
    checkout_id: string
}

export interface ApplePayInfo {
    provider: "apple_pay.0",
    data: {
        merchantIdentifier: string,
        merchantCapabilities: ApplePayJS.ApplePayMerchantCapability[],
        supportedNetworks: string[],
        countryCode: string,
        billingContact: {
            emailAddress: string,
            givenName: string,
            familyName: string
        },
        total: {
            type: "final",
            label: string,
            amount: string,
            paymentTiming: "immediate"
        },
        lineItems: {
            type: "final",
            label: string,
            amount: string,
            paymentTiming: ApplePayJS.ApplePayPaymentTiming
        }[],
        currencyCode: string
    }
}

export default new class ApiClient {
    apiUrl = "https://api.billet.2221.ch"
    headers = new Headers()

    async fetch(method: "GET"|"POST", endpoint:string, body?:unknown, headers?:Headers){
        console.log(`[2221] ${method} ${endpoint}`)
        const h = new Headers(this.headers)
        for(const key of headers?.keys() ?? []){
            h.set(key, headers!.get(key)!)
        }
        
        let b = null
        if(method !== "GET"){
            b = JSON.stringify(body)
            h.set("Content-Type", "application/json")
        }

        const res = await fetch(this.apiUrl+endpoint, {
            headers: h,
            body: b,
            redirect: "manual",
            method: method
        })
        if(!res.ok){
            const text = await res.text()
            try{
                const json = JSON.parse(text)
                throw new Error(json.error)
            }catch(err){
                throw new Error(text)
            }
        }
        return res
    }
    
    async getOrder(orderId: string, token: string): Promise<Order> {
        const res = await this.fetch("GET", `/orders/${orderId}`, null, new Headers({
            authorization: `Bearer ${token}`
        }))
        return res.json()
    }
    
    async getOrderCard(orderId: string, token: string): Promise<CardInfo> {
        const res = await this.fetch("GET", `/checkout/${orderId}/card`, null, new Headers({
            authorization: `Bearer ${token}`
        }))
        return res.json()
    }
    
    async getOrderApplePay(orderId: string, token: string): Promise<ApplePayInfo> {
        const res = await this.fetch("GET", `/checkout/${orderId}/apple_pay`, null, new Headers({
            authorization: `Bearer ${token}`
        }))
        return res.json()
    }
}