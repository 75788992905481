import { Alert, Box, Button, CircularProgress, Divider, TextField, Typography } from "@mui/material";
import { useParams } from "react-router-dom";
import usePromise from "../hooks/usePromise";
import api, { OrderStatus } from "../api";
import { useMemo, useState } from "react";
import luhn from "luhn";
import { formatCreditCard, wait } from "../utils";
import { LoadingButton } from "@mui/lab";
import useIsApplePayAvailable from "../hooks/useIsApplePayAvailable";
import sumup_api from "../sumup_api";
import * as Sentry from "@sentry/react";
import toast from "react-hot-toast";

export default function PaymentForm() {
    const { order_id, token } = useParams()
    
    const [orderFinished, order, orderError] = usePromise(async () => {
        return api.getOrder(order_id!, token!)
    }, [])
    const [sumupFinished, sumup, sumupError] = usePromise(async () => {
        if(!order)return null
        if(order.status != OrderStatus.pending)return null

        const cardInfo = await api.getOrderCard(order_id!, token!)

        if(cardInfo.provider !== "sumup.0") {
            throw new Error(`Unsupported card provider: ${cardInfo.provider}`)
        }
        return cardInfo
    }, [order])

    const isApplePayAvailable = useIsApplePayAvailable()
    const [applePayLoaded, applePayData, applePayError] = usePromise(async () => {
        if(!isApplePayAvailable)return null
        
        const data = await api.getOrderApplePay(order_id!, token!)
        
        if(data.provider != "apple_pay.0"){
            throw new Error(`Unsupported apple pay provider: ${data.provider}`)
        }

        return data.data
    }, [isApplePayAvailable, order_id, token])

    const [name, setName] = useState<string>("")
    const isNameValid = useMemo(() => {
        return name.length > 0
    }, [name])
    const [number, setNumber] = useState<string>("")
    const isNumberValid = useMemo(() => {
        if(number.length !== 16)return false
        return luhn.validate(number)
    }, [number])
    const [exp, setExp] = useState<string>("")
    const isExpValid = useMemo(() => {
        if(exp.length == 5){
            const [month, year] = exp.split("/")
            if(month.length !== 2)return false
            if(year.length !== 2)return false
            if(parseInt(month) < 1 || parseInt(month) > 12)return false
            if(parseInt(year) < 0 || parseInt(year) > 99)return false
        }else if(exp.length == 7){
            const [month, year] = exp.split("/")
            if(month.length !== 2)return false
            if(year.length !== 4)return false
            if(parseInt(month) < 1 || parseInt(month) > 12)return false
            if(parseInt(year) < 2000 || parseInt(year) > 2099)return false
        }else{
            return false
        }

        return true
    }, [exp])
    const [cvc, setCvc] = useState<string>("")
    const isCvcValid = useMemo(() => {
        if(cvc.length !== 3)return false
        return true
    }, [cvc])

    const amount = useMemo(() => {
        if(!order) return "0.00"

        return (order.price.amount / 100).toFixed(2)
    }, [order])
    const currency = useMemo(() => {
        if(!order) return "CHF"
        return order.price.currency
    }, [order])

    const [loading, setLoading] = useState(false)

    const processSumupResponse = async (res: any, is_apple_pay = false) => {
        if("next_step" in res){
            // navigate to the 3DSecure page
            const form = document.createElement("form")
            form.method = res.next_step.method
            form.action = res.next_step.url
            form.style.display = "hidden"

            for(const [key, value] of Object.entries(res.next_step.payload || {})){
                const input = document.createElement("input")
                input.name = key
                input.value = value as any
                input.hidden = true
                form.appendChild(input)
            }

            document.body.appendChild(form)
            form.submit()
        }else{
            if(res.status == "FAILED"){
                throw new Error("Payment failed: "+JSON.stringify(res))
            }

            // Success
            const redirect_url = `${res.redirect_url ?? `${api.apiUrl}/checkout/${order_id!}/result`}?checkout_id=${sumup!.checkout_id}`
            toast.success("Paiement effectué avec succès")
            // this will be caught by the app underneath,
            // or redirected to the frontend by the server
            if(!is_apple_pay){
                await wait(2000)
                window.location.href = redirect_url
            }
            
            return redirect_url
        }
    }

    if(order && order.status != OrderStatus.pending){
        return <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            margin: "auto",
            padding: 1,
            gap: 1,
            maxWidth: "600px",
            flexDirection: "column",

            "& > *": {
                width: "100%"
            }
        }}>
            <Alert severity="info">Cette commande n'est plus disponible</Alert>
            <Button href="/">Retourner à l'application</Button>
        </Box>
    }

    if(orderError || sumupError){
        return <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            margin: "auto",
            padding: 1,
            gap: 1,
            maxWidth: "600px",
            flexDirection: "column",

            "& > *": {
                width: "100%"
            }
        }}>
            <Alert severity="error">{(orderError || sumupError!).toString()}</Alert>
            <Button href="/">Retourner à l'application</Button>
        </Box>
    }

    if(!orderFinished || !sumupFinished || !applePayLoaded){
        return <Box sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
            margin: "auto",
            padding: 1,
            maxWidth: "600px",
            flexDirection: "column",
        }}>
            <CircularProgress />
        </Box>
    }

    return <Box sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
        margin: "auto",
        flexDirection: "column",
    }}>
        <Box sx={{
            marginTop: 1,
        }}>
            <img src={"/wide_logo_dark_transparent.png"} draggable={false} style={{
                height: "98px",
            }}/>
        </Box>
        <Divider sx={{
            width: "100%",
        }}/>
        <Box>
            <Box sx={{
                display: "flex",
                flexDirection: "row",
                gap: 1,
                alignItems: "center",
            }}>
                <Typography sx={{
                    fontSize: "4rem",
                    fontWeight: "bold"
                }}>
                    {amount}
                </Typography>
                <Typography sx={{
                    fontSize: "2.5rem",
                    color: "text.secondary"
                }}>
                    {currency}
                </Typography>
            </Box>
        </Box>
        <Divider sx={{
            width: "100%",
            marginBottom: 2
        }}/>


        <form action="/" onSubmit={async (ev) => {
            ev.preventDefault()
            if(loading)return

            setLoading(true)

            try{
                const res = await sumup_api.processCardCheckout(sumup!.checkout_id, {
                    name: name,
                    number: number,
                    expiry_month: exp.split("/")[0],
                    expiry_year: exp.split("/")[1],
                    cvv: cvc
                })

                await processSumupResponse(res)
            }catch(err){
                console.error(err)
                Sentry.captureException(err)
                toast.error("Erreur lors du paiement; Vérifiez vos informations de carte de crédit et réessayez.")
            }

            setLoading(false)
        }} style={{
            width: "100%",
            maxWidth: "600px",
        }}>
            <Box sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
                flexDirection: "column",
                gap: 2,
                paddingLeft: 2,
                paddingRight: 2,
            }}>
                {isApplePayAvailable && !applePayError && <>
                    <LoadingButton
                        color="apple"
                        variant="contained"
                        sx={{
                            width: "100%",
                            textTransform: "none",
                            marginTop: 2,
                        }}
                        loading={loading}
                        onClick={async () => {
                            setLoading(true)
                            const session = new ApplePaySession(1, applePayData!)
                            session.begin()

                            session.oncancel = () => {
                                setLoading(false)
                            }

                            session.onvalidatemerchant = async (ev) => {
                                const payload = {
                                    target: ev.validationURL,
                                    context: location.hostname
                                }
                                const validation = await sumup_api.initiateApplePaySession(sumup!.checkout_id, payload)

                                session.completeMerchantValidation(validation)
                            }

                            session.onpaymentauthorized = async (ev) => {
                                
                                try{
                                    const res = await sumup_api.processApplePayCheckout(sumup!.checkout_id, ev.payment)

                                    const redirect_url = await processSumupResponse(res)
                                    session.completePayment(ApplePaySession.STATUS_SUCCESS)

                                    await wait(2000)
                                    window.location.href = redirect_url!
                                }catch(err){
                                    console.error(err)
                                    Sentry.captureException(err)
                                    toast.error("Erreur lors du paiement; Vérifiez vos informations de carte de crédit et réessayez.")
                                    session.completePayment(ApplePaySession.STATUS_FAILURE)
                                }
                                setLoading(false)
                            }
                        }}
                    >
                        Payer avec Pay
                    </LoadingButton>

                    <Divider sx={{
                        width: "100%",
                        marginTop: 2,
                        marginBottom: 2
                    }} />
                </>}

                <TextField
                    fullWidth
                    label="Nom sur la carte"
                    value={name}
                    onChange={(e) => {
                        setName(e.target.value)
                    }}
                    autoComplete="cc-name"
                    placeholder="John Doe"
                />
                <TextField
                    fullWidth
                    label="N° de carte"
                    value={formatCreditCard(number)}
                    type=""
                    onChange={(e) => {
                        setNumber(e.target.value?.replace(/[^0-9]/g, "") || "")
                    }}
                    autoComplete="cc-number"
                    error={!!(number && !isNumberValid)}
                    placeholder="1234 5678 9012 3456"
                />
                <Box sx={{
                    display: "flex",
                    flexDirection: "row",
                    gap: 2,
                    alignItems: "center",
                    width: "100%"
                }}>
                    <TextField
                        fullWidth
                        label="Date d'expiration"
                        value={exp}
                        onChange={(e) => {
                            if(exp.length == 1 && e.target.value.length == 2)e.target.value += "/"
                            setExp(
                                e.target.value.replace(/[^0-9/]/g, "")
                                    .replace(/\/{2,}/g, "/")
                            )
                        }}
                        autoComplete="cc-exp"
                        error={!!(exp && !isExpValid)}
                        helperText={exp && !isExpValid ? "Doit suivre le format MM/AA" : undefined}
                        placeholder="MM/AA"
                    />
                    <TextField
                        fullWidth
                        label="CVV"
                        value={cvc}
                        onChange={(e) => {
                            setCvc(e.target.value.replace(/[^0-9]/g, ""))
                        }}
                        autoComplete="cc-csc"
                        error={!!(cvc && !isCvcValid)}
                        placeholder="123"
                    />
                </Box>

                <LoadingButton
                    sx={{
                        width: "100%"
                    }}
                    variant="outlined"
                    type="submit"
                    loading={loading}
                    disabled={!isNameValid || !isNumberValid || !isExpValid || !isCvcValid}
                >
                    Payer {amount} {currency}
                </LoadingButton>
            </Box>
        </form>
    </Box>
}