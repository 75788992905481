import { createTheme } from "@mui/material";

const { palette: { augmentColor } } = createTheme()

export default createTheme({
    palette: {
        mode: "dark",
        apple: augmentColor({ color: { main: "#ffffff" } }),
        
        background: {
            default: "#000000",
        }
    }
})