import { Buffer } from "buffer";
window.Buffer = Buffer;
import * as Sentry from "@sentry/react";

Sentry.init({
    dsn: "https://2ec3658447de8761e8a9b41bb5015481@o4507069295689728.ingest.de.sentry.io/4507302010224720",
    integrations: [
      Sentry.browserTracingIntegration(),
      // **DO NOT enable REPLAY session**
      // this would cause the card details to be sent to Sentry
    ],
});


import ReactDOM from "react-dom/client"
import App from "./App"
import "@fontsource/roboto/300.css";
import "@fontsource/roboto/400.css";
import "@fontsource/roboto/500.css";
import "@fontsource/roboto/700.css";
import "@fontsource/overpass-mono";

const resize = () => {
    document.documentElement.style.setProperty("--window-height", `${window.innerHeight}px`)
}
resize()
window.addEventListener("resize", resize)

ReactDOM.createRoot(document.getElementById("root") as HTMLElement).render(
    <App />
)